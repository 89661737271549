<template>
  <div
    class="progress-bar ProgressBar"
    :style="`--color:${color || 'var(--dcolor)'};--height:${height || Go.math($mpadding, '/', 1.5)};--percent:${barPercent}%`"
  >
    <div class="progress-bar-line"></div>
  </div>
</template>

<script>
export default {
  props: ["height", "color", "percent", "progress"],
  computed: {
    barPercent: function() {
      return this.progress || this.percent || 0;
    },
  },
};
</script>

<style lang="scss">
.progress-bar {
  position: relative;
  background-color: $alto;
  width: 100%;
  border-radius: 2pc;
  overflow: hidden;
  &-line {
    height: var(--height);
    width: var(--percent);
    border-radius: 2pc;
    background-color: var(--color);
  }
}
</style>
